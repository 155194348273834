export default {
    computed: {
        headers() {
            const fields = [
                {
                    value: 'id',
                    text: this.$t('labels.id'),
                    type: 'string',
                },
                {
                    value: 'domain',
                    text: this.$t('labels.domain'),
                    type: 'string',
                },
                {
                    value: 'key',
                    text: this.$t('labels.language_token'),
                    type: 'string',
                },
            ]

            this.availableLocales.forEach((locale, index) => {
                fields.push({
                    value: `translations[${index}].content`,
                    text:
                        locale === 'hu'
                            ? this.$t('labels.language.hungarian')
                            : locale === 'de'
                            ? this.$t('labels.language.german')
                            : this.$t('labels.language.english'),
                    type: 'string',
                })
            })

            return fields
        },
    },
}

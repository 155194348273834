<template>
    <div>
        <page-title>{{ $t('menu.translations') }}</page-title>
        <translations-table></translations-table>
    </div>
</template>

<script>
import PageTitle from '@/components/layout/components/pageTitle'
import TranslationsTable from '@/components/pages/translations/Table'
import routeType from '@/router/routeType'

export default {
    components: {
        PageTitle,
        TranslationsTable,
    },
    data() {
        return {
            routeType,
            filters: {},
        }
    },
}
</script>

export default {
    computed: {
        listFilters() {
            return [
                {
                    key: 'domain',
                    type: 'partial',
                    label: this.$t('labels.domain'),
                    inputType: 'search',
                    quick: 'true',
                },
                {
                    key: 'key',
                    type: 'partial',
                    label: this.$t('labels.language_token'),
                    inputType: 'search',
                    quick: 'true',
                },
                {
                    key: 'translations.content',
                    type: 'partial',
                    label: this.$t('labels.translation'),
                    inputType: 'search',
                    quick: 'true',
                },
            ]
        },
    },
}

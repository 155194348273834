<template>
    <table-container>
        <data-iterator ref="iterator" :api="api" :filters.sync="filters">
            <template v-slot:filters="{ busy }">
                <table-filters
                    v-model="filters"
                    :fields="headers"
                    :busy="busy"
                    :list-filters="listFilters"
                    :hide-search-box="true"
                ></table-filters>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :items="items"
                    :headers="headers"
                    :filters.sync="filters"
                    @onRowSelected="onRowSelected"
                >
                    <template
                        v-for="(locale, i) in availableLocales"
                        v-slot:[`item.translations[${i}].content`]="{ item }"
                    >
                        <v-textarea
                            :key="locale.name"
                            :value="buildTranslations(item)[locale]"
                            rows="2"
                            class="mt-4"
                            auto-grow
                            filled
                            dense
                            @change="
                                update(
                                    $event,
                                    item,
                                    buildTranslations(item),
                                    locale
                                )
                            "
                        ></v-textarea>
                    </template>
                </d-table>
            </template>
        </data-iterator>
    </table-container>
</template>

<script>
import RestApiType from '@/api/RestApiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import TranslationsApiClient from '@/api/RestApi/TranslationsApiClient'
import { mapGetters } from 'vuex'
import configType from '@/store/type/configType'
import Headers from '@/pages/translations/mixins/Headers'
import ListFilters from '@/pages/translations/mixins/ListFilters'

export default {
    mixins: [DataIteratorMixin, DataTableMixin, Headers, ListFilters],

    data() {
        return {
            api: RestApiType.TRANSLATIONS,
        }
    },
    computed: {
        tableName() {
            return this.$t('labels.translations')
        },
        ...mapGetters({
            availableLocales: configType.getters.LOCALES,
        }),
    },

    methods: {
        buildTranslations(item) {
            let translations = {}

            this.availableLocales.forEach((locale) => {
                translations[locale] = ''
            })

            item.translations.forEach((translation) => {
                translations[translation.locale] = translation.content
            })

            return translations
        },

        update(content, item, translations, lang) {
            translations[lang] = content

            TranslationsApiClient.update(item.id, {
                translations: translations,
            }).then(() => {
                this.updateTableItems()
            })
        },
    },
}
</script>
